import React from "react"
import TabNavigation from "../../components/TabNavigation"
import LayoutBasic from "../../components/LayoutBasic"
import BasicText from "../../components/BasicText"
import Info2column from "../../components/Info2column"
import ModalLink from "../../components/ModalLink"
import ColumnGrid from "../../components/ColumnGrid"
import css from "./stylesheets/basicInformation.module.scss"

import Seo from "../../components/Seo"

import RubyConvert from "../../components/RubyConvert"
import IconInfo from "../../images/icon_info.svg"
import CenterContentLayout from "../../components/CenterContentLayout"

function PageBasicInformation(props) {
  return (
    <div>
      <Seo title={`ABOUT JAPAN | BASIC INFORMATION`} />

      <LayoutBasic lang={`easyja`}>
        <CenterContentLayout>
          <BasicText>
            <h1>BASIC INFORMATION</h1>
            <p>
              <RubyConvert>{`{[日本/にほん]は}{どんな}{[国/くに]？}{[日本/にほん]で}{[介護/かいご]の}{[仕事/しごと]を}{するには？}`}</RubyConvert>
              <br />
              <RubyConvert>{`{[日本/にほん]の}{[基本的/きほんてき]な}{[情報/じょうほう]や、[介護/かいご]の}{[仕事/しごと]を}{する}{ための}{4つの}{[在留資格/ざいりゅうしかく]}{などを}{[紹介/しょうかい]します。}`}</RubyConvert>
            </p>
          </BasicText>
        </CenterContentLayout>

        <CenterContentLayout spFit={true}>
          <TabNavigation
            links={[
              {
                title: `ABOUT JAPAN`,
                link: `/basic-information/`,
                current: true,
              },
              { title: `ABOUT KAIGO`, link: `/basic-information/kaigo/` },
            ]}
          />
        </CenterContentLayout>

        <CenterContentLayout>
          <BasicText>
            <h2 className={css.BasicInfoH2}>ABOUT JAPAN</h2>
          </BasicText>
        </CenterContentLayout>

        <CenterContentLayout spFit={true}>
          <Info2column
            id={`section-1`}
            noBorder={true}
            rightWithSpPadding={true}
          >
            <div>
              <img
                src={`/assets/images/basic_info/basic_info_01.svg`}
                alt="面積"
                width={600}
                height={400}
              />
            </div>
            <div className={css.BasicInfoSummary}>
              <hr />
              <dl>
                <dt>
                  <RubyConvert>{`[面積/めんせき]`}</RubyConvert>:
                </dt>
                <dd>
                  378,000km<sup>2</sup>
                </dd>
              </dl>
              <p>
                <RubyConvert>{`{[東/ひがし]アジアで}{[一番/いちばん]}{[大/おお]きい}{[島国/しまぐに]です。}{[面積/めんせき]は}{[世界/せかい]で}{[約/やく]60[番目/ばんめ]に}{[大/おお]きいです。}{[日本/にほん]と}{[同/おな]じくらいの}{[広/ひろ]さの}{[国/くに]は、アジアでは}{ベトナムや}{マレーシア、}{ヨーロッパでは}{ドイツなどです。}{[北海道/ほっかいどう]、}{[本州/ほんしゅう]、}{[四国/しこく]、}{[九州/きゅうしゅう]、}{[沖縄/おきなわ]}{という}{5つの}{[島/しま]の}{ほか、}{6,800[以上/いじょう]の}{[島/しま]が}{あります。}`}</RubyConvert>
              </p>
            </div>
          </Info2column>
          <Info2column
            id={`section-2`}
            noBorder={true}
            rightWithSpPadding={true}
          >
            <div>
              <img
                src={`/assets/images/basic_info/basic_info_02.svg`}
                alt="人口"
                width={600}
                height={400}
              />
            </div>
            <div className={css.BasicInfoSummary}>
              <hr />
              <dl>
                <dt>
                  <RubyConvert>{`[人口/じんこう]`}</RubyConvert>:
                </dt>
                <dd>
                  <RubyConvert>{`[約/やく]1[億/おく]2,602[万人/まんにん]`}</RubyConvert>
                  <sup>※1</sup>
                </dd>
              </dl>
              <dl>
                <dt>
                  <RubyConvert>{`[平均年齢/へいきんねんれい]`}</RubyConvert>:
                </dt>
                <dd>
                  <RubyConvert>{`46.4[歳/さい]`}</RubyConvert>
                </dd>
              </dl>
              <p>
                <RubyConvert>{`{[日本/にほん]の}{[人口/じんこう]は、}{2008[年/ねん]には}{1[億/おく]2,808[万人/まんにん]でした。}{いままでで}{[一番/いちばん]}{[多/おお]かったです。}{そのときから}{[減/へ]っています。}{2019[年/ねん]は、}{15～64[歳/さい]の}{[人/ひと]が}{[全体/ぜんたい]の}{[約/やく]60%でした。}{65[歳/さい][以上/いじょう]の}{[人/ひと]が}{[約/やく]28%でした。}{[世界/せかい]で}{[一番/いちばん]}{[高齢者/こうれいしゃ]の}{[割合/わりあい]が}{[高/たか]いです。}`}</RubyConvert>
                <sup>※2</sup>
              </p>
            </div>
          </Info2column>
          <Info2column
            id={`section-3`}
            noBorder={true}
            rightWithSpPadding={true}
          >
            <div>
              <img
                src={`/assets/images/basic_info/basic_info_03.svg`}
                alt="言語"
                width={600}
                height={400}
              />
            </div>
            <div className={css.BasicInfoSummary}>
              <hr />
              <dl>
                <dt>
                  <RubyConvert>{`[言語/げんご]`}</RubyConvert>:
                </dt>
                <dd>
                  <RubyConvert>{`[日本/にほん][語/ご]`}</RubyConvert>
                </dd>
              </dl>
              <p>
                <RubyConvert>{`{[日本/にほん][語/ご]では、}{「[漢字/かんじ]」}{「ひらがな」}{「カタカナ」}{という}{3[種類/しゅるい]の}{[文字/もじ]を}{[使/つか]います。}{「ローマ[字/じ]」を}{[使/つか]う}{ことも}{あります。}{また、}{[話/はな]し[言葉/ことば]は、}{[地域/ちいき]によって}{[方言/ほうげん]が}{あります。}`}</RubyConvert>
              </p>
            </div>
          </Info2column>
          <Info2column
            id={`section-4`}
            noBorder={true}
            rightWithSpPadding={true}
          >
            <div>
              <img
                src={`/assets/images/basic_info/basic_info_04.svg`}
                alt="通貨"
                width={600}
                height={400}
              />
            </div>
            <div className={css.BasicInfoSummary}>
              <hr />
              <dl>
                <dt>
                  <RubyConvert>{`[通貨/つうか]`}</RubyConvert>:
                </dt>
                <dd>
                  <RubyConvert>{`[円/えん]`}</RubyConvert>（￥）
                </dd>
              </dl>
              <p>
                <RubyConvert>{`{コインが}{6[種類/しゅるい]、[紙/かみ]の}{お[金/かね]が}{4[種類/しゅるい]}{あります。}{1USドル＝[約/やく]110[円/えん]（2020[年/ねん]2[月/がつ]）です。}{[都会/とかい]の}{[駅/えき]や}{お[店/みせ]では、}{[電子/でんし]マネーが}{[使/つか]える}{ところも}{[増/ふ]えてきています。}`}</RubyConvert>
              </p>
            </div>
          </Info2column>
          <Info2column
            id={`section-5`}
            noBorder={true}
            rightWithSpPadding={true}
          >
            <div>
              <img
                src={`/assets/images/basic_info/basic_info_05.svg`}
                alt="宗教"
                width={600}
                height={400}
              />
            </div>
            <div className={css.BasicInfoSummary}>
              <hr />
              <dl>
                <dt>
                  <RubyConvert>{`[宗教/ しゅうきょう]`}</RubyConvert>:
                </dt>
                <dd>
                  <RubyConvert>{`{[神道/しんとう]、}{[仏教/ぶっきょう]、}`}</RubyConvert>
                  <br />
                  <RubyConvert>{`{キリスト[教/きょう]、}{その[他/た]}`}</RubyConvert>
                </dd>
              </dl>
              <p>
                <RubyConvert>{`{[日本/にほん]の}{[文化/ぶんか]の}{なかには、[正月/しょうがつ]に}{[神社/じんじゃ]や}{[寺院/じいん]に}{[行/い]く}{「[初詣/はつもうで]」、[先祖/せんぞ]や}{[亡/な]くなった}{[家族/かぞく]のために}{[祈/いの]る}{「お[盆/ぼん]」、クリスマス}{などが}{あります。}{[神道/しんとう]、[仏教/ぶっきょう]、キリスト[教/きょう]}{など}{いろいろな}{[宗教文化/しゅうきょうぶんか]が}{[混/ま]ざっています。}{[生活/せいかつ]の}{なかで}{[宗教/しゅうきょう]を}{[意識/いしき]しない}{[人/ひと]も}{[多/おお]いです。}`}</RubyConvert>
              </p>
            </div>
          </Info2column>
          <Info2column
            id={`section-6`}
            noBorder={true}
            rightWithSpPadding={true}
          >
            <div>
              <img
                src={`/assets/images/basic_info/basic_info_06.svg`}
                alt="政治"
                width={600}
                height={400}
              />
            </div>
            <div className={css.BasicInfoSummary}>
              <hr />
              <dl>
                <dt>
                  <RubyConvert>{`[政治/せいじ]`}</RubyConvert>:
                </dt>
                <dd>
                  <RubyConvert>{`[民主/みんしゅ][主義/しゅぎ]`}</RubyConvert>
                </dd>
              </dl>
              <p>
                <RubyConvert>{`{[日本/にほん]は、}{[民主/みんしゅ][主義/しゅぎ]を}{[大切/たいせつ]にする}{[国/くに]です。}{[政治/せいじ]は、[国民/こくみん]が}{[選挙/せんきょ]で}{[選/えら]んだ}{[議員/ぎいん]が、[国民/こくみん]の}{[代/か]わりに}{[行/おこな]う}{ことに}{なっています。}{[日本/にほん]の}{[憲法/けんぽう]には、}{「[国民/こくみん][主権/しゅけん]」}{「[基本的/きほんてき][人権/じんけん]の [尊重/そんちょう]」}{「[平和/へいわ][主義/しゅぎ]」が}{[書/か]かれています。}`}</RubyConvert>
              </p>
            </div>
          </Info2column>
          <Info2column
            id={`section-7`}
            noBorder={true}
            rightWithSpPadding={true}
          >
            <div>
              <img
                src={`/assets/images/basic_info/basic_info_07.svg`}
                alt="気候"
                width={600}
                height={400}
              />
            </div>
            <div className={css.BasicInfoSummary}>
              <hr />
              <dl>
                <dt>
                  <RubyConvert>{`[気候/きこう]`}</RubyConvert>:
                </dt>
                <dd>
                  <RubyConvert>{`[亜寒/あかん][帯/たい]〜[亜熱/あねっ][帯/たい]`}</RubyConvert>
                </dd>
              </dl>
              <p>
                <RubyConvert>{`{[日本/にほん]は}{[南北/なんぼく]に}{[長/なが]い}{ため、[地域/ちいき]によって}{[気候/きこう]は}{いろいろです。}{ただ、[温帯/おんたい]の}{[地域/ちいき]が}{[多/おお]く、}{[春/はる]・}{[夏/なつ]・}{[秋/あき]・}{[冬/ふゆ]の}{4つの}{[季節/きせつ]の}{[違/ちが]いが}{はっきりしています。}{[天気/てんき]は、[冬/ふゆ]は}{[日本/にほん][海/かい]に}{[近/ちか]いほうでは}{[曇/くも]りや}{[雪/ゆき]、[雨/あめ]の}{[日/ひ]が}{[多/おお]く、[太平/たいへい][洋/よう]に}{[近/ちか]いほうでは}{[晴/は]れの}{[日/ひ]が}{[多/おお]くなる}{など、[地域/ちいき]によって}{[違/ちが]いが}{あります。}`}</RubyConvert>
              </p>
            </div>
          </Info2column>
        </CenterContentLayout>

        <CenterContentLayout>
          <BasicText>
            <hr className={`only-pc`} />
            <h4 style={{ fontSize: `16px` }}>
              <RubyConvert>{`[各/かく][都市/とし]`}</RubyConvert>
              <sup>※3</sup>:
            </h4>
          </BasicText>
          <div className={css.BasicInfoCityGrid}>
            <ColumnGrid column={4} columnSp={2}>
              <ModalLink id={`modal-1`}>
                <div className={css.BasicInfoGridModalLinksItem}>
                  <img
                    src={`/assets/images/basic_info/basic_info_city_01.svg`}
                    alt="札幌市（北海道）"
                    width={280}
                    height={190}
                  />
                  <span>
                    <RubyConvert>{`[札幌/さっぽろ][市/し]（[北海/ほっかい][道/どう]）`}</RubyConvert>
                    <img src={IconInfo} alt="" />
                  </span>
                </div>
                <div>
                  <h3>
                    <RubyConvert>{`[札幌/さっぽろ][市/し]（[北海/ほっかい][道/どう]）`}</RubyConvert>
                  </h3>
                  <p>
                    <img
                      src={`/assets/images/basic_info/basic_info_city_01.svg`}
                      alt="札幌市（北海道）"
                      width={280}
                      height={190}
                    />
                  </p>
                  <p>
                    <RubyConvert>{`{8[月/がつ]の}{[最高/さいこう][気温/きおん]は}{[平均/へいきん]で}{26.4℃。}{[梅雨/つゆ]や}{[台風/たいふう]の}{[影響/えいきょう]が}{[少/すく]なくて}{[過/す]ごしやすいです。}{1[月/がつ]の}{[最低/さいてい][気温/きおん]は}{[平均/へいきん]で}{-7℃。}{[日本/にほん]の}{なかでは}{[寒/さむ]い}{[地域/ちいき]ですが、[部屋/へや]の}{なかは}{[暖房/だんぼう]が}{ある}{ところが}{[多/おお]いです。}{また、[雪/ゆき]が}{[降/ふ]ります。}{1m[以上/いじょう]}{[積/つ]もる}{ことも}{あります。}{ウィンタースポーツが}{[楽/たの]しめる}{スキー[場/じょう]が}{あります。}{[農業/のうぎょう]と}{[漁業/ぎょぎょう]が}{[盛/さか]んです。}{[食/た]べ[物/もの]は、}{[寿司/すし]、}{[魚/さかな]や[貝/かい]の}{[料理/りょうり]、}{ジンギスカン、}{ラーメン、}{スープカレー}{などが}{[有名/ゆうめい]です。}`}</RubyConvert>
                  </p>
                </div>
              </ModalLink>
              <ModalLink id={`modal-2`}>
                <div className={css.BasicInfoGridModalLinksItem}>
                  <img
                    src={`/assets/images/basic_info/basic_info_city_02.svg`}
                    alt="仙台市（宮城県）"
                    width={280}
                    height={190}
                  />
                  <span>
                    <RubyConvert>{`[仙台/せんだい][市/し]（[宮城/みやぎ][県/けん]）`}</RubyConvert>
                    <img src={IconInfo} alt="" />
                  </span>
                </div>
                <div>
                  <h3>
                    <RubyConvert>{`[仙台/せんだい][市/し]（[宮城/みやぎ][県/けん]）`}</RubyConvert>
                  </h3>
                  <p>
                    <img
                      src={`/assets/images/basic_info/basic_info_city_02.svg`}
                      alt="仙台市（宮城県）"
                      width={280}
                      height={190}
                    />
                  </p>
                  <p>
                    <RubyConvert>{`{[東北/とうほく][地方/ちほう]で}{[一番/いちばん]}{[人口/じんこう]が}{[多/おお]いです。}{[東京/とうきょう]には}{[新幹/しんかん][線/せん]で}{[約/やく]1[時間/じかん][半/はん]で}{[行/い]けます。}{8[月/がつ]の}{[最高/さいこう][気温/きおん]は}{[平均/へいきん]で}{27.9℃、1[月/がつ]の}{[最低/さいてい][気温/きおん]は}{[平均/へいきん]で}{-1.7℃。}{[東北/とうほく]は}{[雪/ゆき]が}{[多/おお]く}{[降/ふ]る}{イメージが}{ありますが、[仙台/せんだい]は}{[雪/ゆき]が}{[少/すく]ないです。}{1[年/ねん]}{ずっと}{[過/す]ごしやすいです。}{また、[海/うみ]も}{[山/やま]も}{あり、おいしい}{[食/た]べ[物/もの]が}{[取/と]れます。}{[秋/あき]から}{[冬/ふゆ]に}{[取/と]れる、[牡蠣/かき]や}{サンマ}{などが}{[有名/ゆうめい]です。}{[笹/ささ]かまぼこや}{[牛/ぎゅう]タン}{なども}{[人気/にんき]です。}`}</RubyConvert>
                  </p>
                </div>
              </ModalLink>
              <ModalLink id={`modal-3`}>
                <div className={css.BasicInfoGridModalLinksItem}>
                  <img
                    src={`/assets/images/basic_info/basic_info_city_03.svg`}
                    alt="東京都23区"
                    width={280}
                    height={190}
                  />
                  <span>
                    <RubyConvert>{`[東京/とうきょう]23[区/く]（[東京/とうきょう][都/と]）`}</RubyConvert>
                    <img src={IconInfo} alt="" />
                  </span>
                </div>
                <div>
                  <h3>
                    <RubyConvert>{`[東京/とうきょう]23[区/く]（[東京/とうきょう][都/と]）`}</RubyConvert>
                  </h3>
                  <p>
                    <img
                      src={`/assets/images/basic_info/basic_info_city_03.svg`}
                      alt="東京都23区"
                      width={280}
                      height={190}
                    />
                  </p>
                  <p>
                    <RubyConvert>{`{[東京/とうきょう]は}{[日本/にほん]で}{[一番/いちばん]の}{[都市/とし]。}{[東京/とうきょう][駅/えき]が}{ある}{[千代田/ちよだ][区/く]では、8[月/がつ]の}{[最高/さいこう][気温/きおん]は}{[平均/へいきん]で}{30.8℃、1[月/がつ]の}{[最低/さいてい][気温/きおん]は}{[平均/へいきん]で}{0.9℃です。}{[夏/なつ]は}{[少/すこ]し}{[蒸/む]し[暑/あつ]いです。}{[台風/たいふう]の}{[影響/えいきょう]を}{[受/う]ける}{ことも}{あり、[日本/にほん]の}{なかでは}{[雨/あめ]が}{[降/ふ]る}{[量/りょう]が}{[多/おお]い}{[地域/ちいき]です。}{[食/た]べ[物/もの]は、}{[江戸前/えどまえ][寿司/ずし]、}{もんじゃ[焼/や]き}{などが}{[有名/ゆうめい]です。}{[世界/せかい]の}{いろいろな}{[国/くに]の}{[料理/りょうり]も}{[食/た]べられます。}`}</RubyConvert>
                  </p>
                </div>
              </ModalLink>
              <ModalLink id={`modal-4`}>
                <div className={css.BasicInfoGridModalLinksItem}>
                  <img
                    src={`/assets/images/basic_info/basic_info_city_04.svg`}
                    alt="名古屋市（愛知県）"
                    width={280}
                    height={190}
                  />
                  <span>
                    <RubyConvert>{`[名古屋/なごや][市/し]（[愛知/あいち][県/けん]）`}</RubyConvert>
                    <img src={IconInfo} alt="" />
                  </span>
                </div>
                <div>
                  <h3>
                    <RubyConvert>{`[名古屋/なごや][市/し]（[愛知/あいち][県/けん]）`}</RubyConvert>
                  </h3>
                  <p>
                    <img
                      src={`/assets/images/basic_info/basic_info_city_04.svg`}
                      alt="名古屋市（愛知県）"
                    />
                  </p>
                  <p>
                    <RubyConvert>{`{[本州/ほんしゅう]の}{[真/ま]んなかに}{あります。}{[気候/きこう]は}{[穏/おだ]やかです。}{8[月/がつ]の}{[最高/さいこう][気温/きおん]は}{[平均/へいきん]で}{32.8℃。}{[台風/たいふう]の}{[影響/えいきょう]を}{[受/う]ける}{ことも}{あり、6[月/がつ]や}{7[月/がつ]、9[月/がつ]に}{[雨/あめ]が}{[多/おお]いです。}{1[月/がつ]の}{[最低/さいてい][気温/きおん]は}{[平均/へいきん]で}{0.8℃で、[雨/あめ]は}{[少/すく]ないです。}{[愛知/あいち]は}{[味噌/みそ]が}{[有名/ゆうめい]で、}{[味噌/みそ]カツ、}{[味噌/みそ][煮込/にこ]みうどん}{などの}{[料理/りょうり]が}{あります。}{きしめん、}{[小倉/おぐら]トースト}{なども}{[有名/ゆうめい]です。}`}</RubyConvert>
                  </p>
                </div>
              </ModalLink>
              <ModalLink id={`modal-5`}>
                <div className={css.BasicInfoGridModalLinksItem}>
                  <img
                    src={`/assets/images/basic_info/basic_info_city_05.svg`}
                    alt="大阪市（大阪府）"
                    width={280}
                    height={190}
                  />
                  <span>
                    <RubyConvert>{`[大阪/おおさか][市/し]（[大阪/おおさか][府/ふ]）`}</RubyConvert>
                    <img src={IconInfo} alt="" />
                  </span>
                </div>
                <div>
                  <h3>
                    <RubyConvert>{`[大阪/おおさか][市/し]（[大阪/おおさか][府/ふ]）`}</RubyConvert>
                  </h3>
                  <p>
                    <img
                      src={`/assets/images/basic_info/basic_info_city_05.svg`}
                      alt="大阪市（大阪府）"
                    />
                  </p>
                  <p>
                    <RubyConvert>{`{[大阪/おおさか]は、[日本/にほん]で}{2[番目/ばんめ]の}{[大都市/だいとし]です。}{[世界遺産/せかいいさん]が}{[多/おお]い}{[京都/きょうと]や、[港町/みなとまち]の}{[神戸/こうべ]}{などにも}{[近/ちか]いです。}{8[月/がつ]の}{[最高/さいこう][気温/きおん]は}{[平均/へいきん]で}{33.4℃で、[日本/にほん]の}{なかでは}{[暑/あつ]いほうです。}{1[月/がつ]の}{[最低/さいてい][気温/きおん]は}{[平均/へいきん]で}{2.8℃です。}{[食/た]べ[物/もの]は、たこ[焼/や]きや}{お[好/この]み[焼/や]き、[串/くし]カツ}{などが}{[有名/ゆうめい]です。}{また、[大阪/おおさか]は}{「[笑/わら]いの}{[聖地/せいち]」とも}{[呼/よ]ばれ、ユーモアの}{[文化/ぶんか]も}{あります。}`}</RubyConvert>
                  </p>
                </div>
              </ModalLink>
              <ModalLink id={`modal-6`}>
                <div className={css.BasicInfoGridModalLinksItem}>
                  <img
                    src={`/assets/images/basic_info/basic_info_city_06.svg`}
                    alt="広島市（広島県）"
                    width={280}
                    height={190}
                  />
                  <span>
                    <RubyConvert>{`[広島/ひろしま][市/し]（[広島/ひろしま][県/けん]）`}</RubyConvert>
                    <img src={IconInfo} alt="" />
                  </span>
                </div>
                <div>
                  <h3>
                    <RubyConvert>{`[広島/ひろしま][市/し]（[広島/ひろしま][県/けん]）`}</RubyConvert>
                  </h3>
                  <p>
                    <img
                      src={`/assets/images/basic_info/basic_info_city_06.svg`}
                      alt="広島市（広島県）"
                    />
                  </p>
                  <p>
                    <RubyConvert>{`{[広島/ひろしま]が}{ある}{[瀬戸内海/せとないかい][地域/ちいき]は、[山/やま]に}{[囲/かこ]まれている}{ため、[晴/は]れが}{[多/おお]く、[雨/あめ]が}{[少/すく]ないです。}{とても}{[過/す]ごしやすい}{[地域/ちいき]です。}{8[月/がつ]の}{[最高/さいこう][気温/きおん]は}{[平均/へいきん]で}{32.5℃、1[月/がつ]の}{[最低/さいてい][気温/きおん]は}{[平均/へいきん]で}{1.7℃。}{また、[広島/ひろしま]は}{[日本/にほん]で}{[一番/いちばん]}{[多/おお]く}{[牡蠣/かき]が}{[取/と]れます。}{お[好/この]み[焼/や]きも}{[有名/ゆうめい]で、たくさんの}{お[店/みせ]が}{あります。}{[広島市/ひろしまし]には、}{「[平和記念公園/へいわきねんこうえん]」や}{[世界/せかい][遺産/いさん]の}{「[原爆/げんばく]ドーム」}{などが}{あり、[世界/せかい]に}{[平和/へいわ]を}{[訴/うった]える}{まちでも}{あります。}`}</RubyConvert>
                  </p>
                </div>
              </ModalLink>
              <ModalLink id={`modal-7`}>
                <div className={css.BasicInfoGridModalLinksItem}>
                  <img
                    src={`/assets/images/basic_info/basic_info_city_07.svg`}
                    alt="福岡市（福岡県）"
                    width={280}
                    height={190}
                  />
                  <span>
                    <RubyConvert>{`[福岡/ふくおか][市/し]（[福岡/ふくおか][県/けん]）`}</RubyConvert>
                    <img src={IconInfo} alt="" />
                  </span>
                </div>
                <div>
                  <h3>
                    <RubyConvert>{`[福岡/ふくおか][市/し]（[福岡/ふくおか][県/けん]）`}</RubyConvert>
                  </h3>
                  <p>
                    <img
                      src={`/assets/images/basic_info/basic_info_city_07.svg`}
                      alt="福岡市（福岡県）"
                    />
                  </p>
                  <p>
                    <RubyConvert>{`{[九州/きゅうしゅう][地方/ちほう]で}{[一番/いちばん]の}{[都市/とし]です。}{[日本/にほん]で}{[一番/いちばん]}{ほかの}{アジアの}{[国/くに]に}{[近/ちか]い}{[都市/とし]の}{1つです。}{「アジアフォーカス・[福岡国際映画祭/ふくおかこくさいえいがさい]」「[福岡/ふくおか]アジア[美術館/ びじゅつかん]」}{など、アジア[文化/ぶんか]の}{[交流/こうりゅう]を}{すすめています。}{8[月/がつ]の}{[最高/さいこう][気温/きおん]は}{[平均/へいきん]で}{32.1℃、1[月/がつ]の}{[最低/さいてい][気温/きおん]は}{[平均/へいきん]で}{3.5℃。}{[夏/なつ]から}{[秋/あき]には、[台風/たいふう]の}{[影響/えいきょう]を}{[受/う]けやすいですが、}{[日本/にほん]の}{なかでは}{[暖/あたた]かいほうで}{[過/す]ごしやすいです。}{[有名/ゆうめい]な}{[食/た]べ[物/もの]は、}{もつ[鍋/なべ]、}{[博多/はかた]ラーメン、}{[寿司/すし]、}{[餃子/ぎょうざ]、}{[明太子/めんたいこ]、}{うどん}{など}{たくさん}{あります。}`}</RubyConvert>
                  </p>
                </div>
              </ModalLink>
              <ModalLink id={`modal-8`}>
                <div className={css.BasicInfoGridModalLinksItem}>
                  <img
                    src={`/assets/images/basic_info/basic_info_city_08.svg`}
                    alt="那覇市（沖縄県）"
                    width={280}
                    height={190}
                  />
                  <span>
                    <RubyConvert>{`[那覇/なは][市/し]（[沖縄/おきなわ][県/けん]）`}</RubyConvert>
                    <img src={IconInfo} alt="" />
                  </span>
                </div>
                <div>
                  <h3>
                    <RubyConvert>{`[那覇/なは][市/し]（[沖縄/おきなわ][県/けん]）`}</RubyConvert>
                  </h3>
                  <p>
                    <img
                      src={`/assets/images/basic_info/basic_info_city_08.svg`}
                      alt="那覇市（沖縄県）"
                    />
                  </p>
                  <p>
                    <RubyConvert>{`{[那覇/なは]が}{ある}{[沖縄/おきなわ][本島/ほんとう]は、[美/うつく]しい}{[海/うみ]に}{[囲/かこ]まれています。}{1[年/ねん]}{ずっと}{[気温/きおん]が}{[高/たか]く、[雨/あめ]が}{[多/おお]い}{[地域/ちいき]です。}{8[月/がつ]の}{[最高/さいこう][気温/きおん]は}{[平均/へいきん]で}{31.5℃、1[月/がつ]の}{[最低/さいてい][気温/きおん]は}{[平均/へいきん]で}{14.6℃。}{[冬/ふゆ]も}{ほかの}{[地域/ちいき]ほど}{[気温/きおん]が}{[下/さ]がりません。}{[雪/ゆき]は}{[降/ふ]りません。}{ビーチや}{サンゴ[礁/しょう]、}{[琉球/りゅうきゅう][王国/おうこく][時代/じだい]の}{[遺跡/いせき]}{などが}{あり、[観光/かんこう][地/ち]としても}{[人気/にんき]の}{[場所/ばしょ]です。}{[食/た]べ[物/もの]は、[沖縄/おきなわ]そばや}{[沖縄/おきなわ]の[家庭/かてい][料理/りょうり]が}{[有名/ゆうめい]です。}{タコライスや}{ステーキなど}{アメリカ[料理/りょうり]から}{[生/う]まれた}{[食/た]べ[物/もの]も}{よく}{[食/た]べられています。}`}</RubyConvert>
                  </p>
                </div>
              </ModalLink>
            </ColumnGrid>
          </div>
        </CenterContentLayout>

        <CenterContentLayout spFit={true}>
          <Info2column
            id={`section-9`}
            noBorder={true}
            rightWithSpPadding={true}
          >
            <div>
              <img
                src={`/assets/images/basic_info/basic_info_people.svg`}
                alt="在留外国人数"
                width={600}
                height={400}
              />
            </div>
            <div className={css.BasicInfoSummary}>
              <hr />
              <h3>
                <RubyConvert>{`{[外国/がいこく][人/じん]の}{[数/かず]}`}</RubyConvert>
              </h3>
              <dl>
                <dt>
                  <RubyConvert>{`{[日本/にほん]に}{[住/す]んでいる}{[外国/がいこく][人/じん]の}{[数/かず]} `}</RubyConvert>
                  :
                </dt>
                <dd>
                  <RubyConvert>{`2,829,416[人/にん]`}</RubyConvert>
                  <br />
                  <small>
                    （
                    <RubyConvert>
                      {`[男性/だんせい]：1,387,401[人/にん]／[女性/じょせい]：1,442,015[人/にん]`}
                    </RubyConvert>
                    ）
                  </small>
                  <sup>※4</sup>
                </dd>
              </dl>
              <dl>
                <dt>
                  <RubyConvert>{`{[日本/にほん]で}{[働/はたら]いている}{[外国/がいこく][人/じん]の}{[数/かず]}`}</RubyConvert>
                  :
                </dt>
                <dd>
                  <RubyConvert>{`1,460,463[人/にん]`}</RubyConvert>
                  <br />
                  <small>
                    （
                    <RubyConvert>{`[医療/いりょう]・[福祉/ふくし]：26,086[人/にん]`}</RubyConvert>
                    ）
                  </small>
                  <sup>※5</sup>
                </dd>
              </dl>
              <p>
                <RubyConvert>{`{[日本/にほん]に}{住んでいる}{[外国/がいこく][人/じん]も、[働/はたら]いている}{[外国/がいこく][人/じん]も、だんだん}{[増/ふ]えています。}{[外国/がいこく][人/じん]の}{[国籍/こくせき]は}{[中国/ちゅうごく]、}{ベトナム、}{フィリピンが}{[多/おお]いです。}{また}{[働/はたら]いている}{[外国/がいこく][人/じん]の}{[仕事/しごと]の}{[種類/しゅるい]は、[製造/せいぞう][業/ぎょう]が}{[一番/いちばん]}{[多/おお]いです。}{[医療/いりょう]・[福祉/ふくし][分野/ぶんや]で}{[働/はたら]く}{[外国/がいこく][人/じん]は}{[全体/ぜんたい]の}{1.8%で、まだまだ}{[少/すく]ないほうです。}`}</RubyConvert>
              </p>
            </div>
          </Info2column>
        </CenterContentLayout>

        <CenterContentLayout>
          <BasicText>
            <ul className={`notes`}>
              <li>
                <i>※1</i>
                <span>総務省統計局「統計データ」（2020年1月1日）より。</span>
              </li>
              <li>
                <i>※2</i>
                <span>
                  内閣府「高齢社会白書」（2019年）、独立行政法人労働政策研究・研修機構「データブック国際労働比較2018」より。
                </span>
              </li>
              <li>
                <i>※3</i>
                <span>
                  各地の気温については1981〜2010年の平均値、気象庁「過去の気象データ検索」より。
                </span>
              </li>
              <li>
                <i>※4</i>
                <span>
                  法務省出入国管理庁「報道資料｜令和元年6月末現在における在留外国人数について」（2019年）より。
                </span>
              </li>
              <li>
                <i>※5</i>
                <span>
                  厚生労働省「『外国人雇用状況』の届出状況まとめ」（2018年）より。
                </span>
              </li>
            </ul>
          </BasicText>
        </CenterContentLayout>
      </LayoutBasic>
    </div>
  )
}
export default PageBasicInformation
